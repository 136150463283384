import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Categories, Marketplace, Products, User } from '../../shared/models';
import { BehaviorSubject, zip } from 'rxjs';

interface Pageable{
  content: any,
  totalPages: number,
  totalElements: number
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {

  }
  public onLogoUpload = new BehaviorSubject('')
  public productRefech = new BehaviorSubject(false)
  public clientType = new BehaviorSubject('')

  getUser(){
    return this.http.get<User>(
      `${environment.API_URL}admin/user`
    );
  }
  isSuperAdmin(email: string): boolean{
    return email === "jason@yourexpertly.com"
  }

  // user
  public userInfo() {
    return this.http.get<User>(
      `${environment.API_URL}admin/user`
    );
  }
  public editCompanyInfo(data: Partial<User>) {
    return this.http.put<User>(
      `${environment.API_URL}admin/company-info`,
      data
    );
  }
  public updateUserInfo(data: Partial<User>) {
    return this.http.put<User>(
      `${environment.API_URL}admin/info`,
      data
    );
  }
  public updateSecEmail(body: Partial<User>){
    return this.http.put(`${environment.API_URL}admin/secondary-email`, body);
  }
  public logoUpload(formData: FormData) {
    return this.http.post<User>(
      `${environment.API_URL}admin/logo/upload`,
      formData
    );
  }

  public changePassword(data: any) {
    let body = new URLSearchParams();
    for (const [key, value] of Object.entries(data)) {
      body.set(key, value as any)
    }
    return this.http.post<User>(
      `${environment.API_URL}admin/change/password`,
      body,
      {headers: { 'content-type': 'application/x-www-form-urlencoded' },}
    );
  }

// stripe
public isStripeLinked() {
  return this.http.get<{linked:boolean, link:string}>(
    `${environment.API_URL}admin/link-stripe`
  );
}

public unlinkStripe() {
  return this.http.put<{linked:boolean, link:string}>(
    `${environment.API_URL}admin/unlink-stripe`,{}
  );
}

public linkStripe(email:string, countryCode: string) {
  let body = new URLSearchParams();
  body.set('email', email)
  body.set('countryCode', countryCode)
  return this.http.put<{linked:boolean, link:string}>(
    `${environment.API_URL}admin/link-stripe`,
    body,
    {headers: { 'content-type': 'application/x-www-form-urlencoded' },}

  );
}


  // marketplace
  public marketplace() {
    return this.http.get<Marketplace>(
      `${environment.API_URL}admin/marketplace`
    );
  }
  public editMarketplace(data: any) {
    return this.http.put<Marketplace>(
      `${environment.API_URL}admin/marketplace`,
      data
    );
  }

  public editMarketplacePayment(data: any) {
    return this.http.put<Marketplace>(
      `${environment.API_URL}admin/marketplace/payment`,
      data
    );
  }

  // Categories
  public categories() {
    return this.http.get<Array<Categories>>(
      `${environment.API_URL}admin/categories`
    );
  }
  public createCategori(body: Partial<Categories>) {
    return this.http.post<Categories>(
      `${environment.API_URL}admin/categories`,
      body
    );
  }
  public editCategori(body: Partial<Categories>) {
    return this.http.put<Categories>(
      `${environment.API_URL}admin/categories`,
      body
    );
  }
  public deleteCategory(id: String) {
    return this.http.delete(
      `${environment.API_URL}admin/categories`,
      { 
        body:{id:id}
      }
    );
  }
  public deleteJourney(id:string){
    return this.http.delete(
      `${environment.API_URL}admin/journey-stage`,
      { 
        body:{id:id}
      }
    );
  }

  // jurneyStage
  public getJurneyStages() {
    return this.http.get<Array<{name:string, id: string}>>(
      `${environment.API_URL}admin/journey-stage`
    );
  }

  public createJurneyStage(name: string) {
    return this.http.post<Categories>(
      `${environment.API_URL}admin/journey-stage`,
      {name}
    );
  }

  public editJoruneyStage(body: any){
    return this.http.put<Categories>(
      `${environment.API_URL}admin/journey-stage`,
      body
    );
  }

  // Products
  public deleteProduct(id: string) {
    return this.http.delete(
      `${environment.API_URL}admin/products`,
      { 
        body:{id:id}
      }
    );
  }
  public products(filterBy: string, seeBy: string) {
    return this.http.get<Array<Categories>>(
      `${environment.API_URL}admin/products`,
      {params:{"filterBy":filterBy, seeBy}}
    );
  }
  public createProducts(body:any) {
    return this.http.post<Array<Products>>(
      `${environment.API_URL}admin/products`,
      body
    );
  }
  public editProduct(body: any){
    return this.http.put<Products>(
      `${environment.API_URL}admin/products`,
      body
    );
  }
  public product(id: string){
    return this.http.get<Products>(
      `${environment.API_URL}admin/products/${id}`
    );
  }
  public duplicateProduct(id:string){
    return this.http.post<Products>(
      `${environment.API_URL}admin/duplicate-product`,
      {id:id}
    );
  }
  public productStats(id: string){
    return this.http.get<Products>(
      `${environment.API_URL}admin/stats/product/${id}`
    );
  }

  public unGroupCategory(){
    return this.http.get<Products[]>(
      `${environment.API_URL}admin/products/ungrouped/category`
    );
  }

  public unGroupJourney(){
    return this.http.get<Products[]>(
      `${environment.API_URL}admin/products/ungrouped/journey-stage`
    );
  }


  public featuredProduct(id: string){
    return this.http.put<Products>(
      `${environment.API_URL}admin/featured/product/${id}`,
      {}
    );
  }

  public categoryOrder(body:Array<{id:string}>) {
    return this.http.put(
      `${environment.API_URL}admin/category-order`,
      body
    );
  }

  public productOrder(body:Array<{id:string}>, categoryId: string) {
    return this.http.put(
      `${environment.API_URL}admin/product-order/category/${categoryId}`,
      body
    );
  }

  public journeyOrder(body:Array<{id:string}>) {
    return this.http.put(
      `${environment.API_URL}admin/journey-stage-order`,
      body
    );
  }

  public productJurneyOrder(body:Array<{id:string}>, journeyStageId: string) {
    return this.http.put(
      `${environment.API_URL}admin/product-order/journey-stage/${journeyStageId}`,
      body
    );
  }

  // Buyer
  public buyers(keyword:any,currentPage: string, sort?:{sortBy:string, sortOrder:string}) {
    let body = new URLSearchParams();
    body.set('keyword', keyword)
    body.set('page', currentPage)
    if(sort){
      body.set('sortBy',sort.sortBy)
      body.set('sortOrder',sort.sortOrder)
    }
    return this.http.post<Pageable>(
      `${environment.API_URL}admin/buyers/pageable`,
      body,
      {headers: { 'content-type': 'application/x-www-form-urlencoded' },}
    );
  }

  public updateBuyerStatus(id: string){
    return this.http.put<Products>(
      `${environment.API_URL}admin/buyer/${id}/change-status`,
      {}
    );
  }

  public agecnyOwners(keyword:any,currentPage: string, sort?:{sortBy:string, sortOrder:string}) {
    let body = new URLSearchParams();
    body.set('keyword', keyword)
    body.set('page', currentPage)
    if(sort){
      body.set('sortBy',sort.sortBy)
      body.set('sortOrder',sort.sortOrder)
    }
    return this.http.post<Pageable>(
      `${environment.API_URL}admin/agency-owner/pageable`,
      body,
      {headers: { 'content-type': 'application/x-www-form-urlencoded' },}
    );
  }

  public updateStatus(id: string){
    return this.http.put<Products>(
      `${environment.API_URL}admin/agency-owner/${id}/change-status`,
      {}
    );
  }

  public providers(keyword:string, sort?:{sortBy:string, sortOrder:string}) {
    let body = new URLSearchParams();
    body.set('keyword', keyword)
    if(sort){
      body.set('sortBy',sort.sortBy)
      body.set('sortOrder',sort.sortOrder)
    }
    return this.http.post<Array<any>>(
      `${environment.API_URL}admin/providers`,
      body,
      {headers: { 'content-type': 'application/x-www-form-urlencoded' },}
    );
  }

  public inviteBuyers(to:string){
    return this.http.post<Products>(
      `${environment.API_URL}admin/invitation/buyer`,
      {}
    );
  }

  public inviteProvider(to:string){
    return this.http.post<Products>(
      `${environment.API_URL}admin/invitation/provider`,
      {}
    );
  }

  public getOrder(id: string) {
    return this.http.get<{ url: string }>(
      `${environment.API_URL}admin/orders/${id}`
    );
  }

  getCount(){
    return zip([
      this.http.get(`${environment.API_URL}admin/count-products`),
      this.http.get(`${environment.API_URL}admin/count-providers`),
      this.http.get(`${environment.API_URL}admin/count-offers`),
    ])
  }

  public getInventory(page:number, pageSize: number, sortBy?:any, keyword?: string){
    return this.http.post<any>(
      `${environment.API_URL}admin/provider-offer?page=${page}&size=10&sortBy=${sortBy?sortBy:''}&keyword=${keyword?keyword:''}`,
      {}
    );
  }

  public getOrders(page:number, pageSize: number, sortBy?:any, keyword?: string){
    return this.http.post<any>(
      `${environment.API_URL}admin/orders/pageable?page=${page}&size=10&sortBy=${sortBy?sortBy:''}&keyword=${keyword?keyword:''}`,
      {}
    );
  }

  // SEO
  public setSiteSettings(body: any){
    return this.http.put(
      `${environment.API_URL}admin/site-settings`,
      body
    );
  }

  public buyerRegistrationsEmail(body: any){
    return this.http.put(
      `${environment.API_URL}admin/buyer-registration-email`,
      body
    );
  }
  
  public getSiteSettings(){
    return this.http.get<any>(
      `${environment.API_URL}admin/site-settings`
    );
  }
  public faviconUpload(formData: FormData) {
    return this.http.post(
      `${environment.API_URL}admin/favicon/upload`,
      formData
    );
  }

  public ogImageUpload(formData: FormData) {
    return this.http.post(
      `${environment.API_URL}admin/site-image/upload`,
      formData
    );
  }


}

