<div [ngClass]="{'p-[9px]': isSmall, 'border border-neutral-100 !border-solid' : type != 'admin'}"
    (click)="goToDetails()"
    class="w-full flex p-5 rounded-xl bg-neutral-0 border-dashed border-neutral-200 box-shadow h-full">
    <div class="w-full flex flex-col justify-between gap-5" [ngClass]="{'gap-[10px]': isSmall, '!gap-0': offerId}">
        @if(!offerId){
        <div class="flex items-center justify-between">
            <span [ngClass]="{'text-[5.8px]': isSmall}"
                class="text-neutral-300 text-xs font-medium leading-[18px]">{{journey?.name}}</span>
            @if(type == 'admin'){
            <button (click)="onFeatured(); $event.stopPropagation()"><img
                    [src]="isFeatured ? '/assets/icons/star-fill.svg' : '/assets/icons/star-empty.svg'" alt=""
                    srcset=""></button>
            @if(isSuperAdmin){
            <button (click)="$event.stopPropagation(); onProductDelete()"><img class="w-5 h-5"
                    src="/assets/icons/trash.svg" alt="" srcset=""></button>
            }
            }@else{
            <div>
                <button (click)="onBookmark(); $event.stopPropagation()"><img
                        [src]="isBookmarked ? '/assets/icons/bookmark-fill.svg' : '/assets/icons/bookmark.svg'" alt=""
                        srcset=""></button>
                @if(isFeatured){
                <button><img
                        [src]="isFeatured ? '/assets/icons/star-fill.svg' : '/assets/icons/star-empty.svg'" alt=""
                        srcset=""></button>
                }
            </div>
            }

        </div>

        <div class="flex flex-col">
            <span [ngClass]="{'text-[8.7px]': isSmall}"
                class="text-18 text-neutral-700 overflow-hidden whitespace-nowrap text-ellipsis mb-2">{{title}}</span>
            <span [ngClass]="{'text-[5.8px]': isSmall}"
                class="text-12 text-neutral-600 overflow-hidden whitespace-nowrap text-ellipsis">{{tagline}}</span>
        </div>
        }@else{
        <!-- <button class="self-end absolute" (click)="onBookmark(); $event.stopPropagation()"><img
                [src]="isBookmarked ? '/assets/icons/bookmark-fill.svg' : '/assets/icons/bookmark.svg'" alt=""
                srcset=""></button> -->
        }

        <div class="flex flex-row gap-[18px]">
            <div class="flex flex-row items-center gap-[5px]">
                <div [ngClass]="{'w-4': isSmall}"
                    class="w-8 h-8 rounded-lg bg-neutral-35 mr-1 flex justify-center items-center ">
                    @if(iconCalendar){
                    <img src="/assets/icons/calendar2.svg" alt="" srcset="">
                    }@else {
                    <img src="/assets/icons/clock.svg" alt="" srcset="">
                    }

                </div>
                @if(startDate){
                <div class="flex flex-row">
                    <div class="flex flex-col">
                        <span class="text-black text-14">Start</span>
                        <span [ngClass]="{ '!text-[#9393FF]': isFastest}"
                            class="text-neutral-700 text-14 font-semibold whitespace-nowrap">
                            {{startDate | date: 'MMM d'}} &nbsp; - &nbsp;
                        </span>

                    </div>
                    <div class="flex flex-col">
                        <span class="text-black text-14">Finish</span>
                        <span [ngClass]="{ '!text-[#9393FF]': isFastest}"
                            class="text-neutral-700 text-14 font-semibold whitespace-nowrap">
                            {{endDate | date: 'MMM d'}}
                        </span>

                    </div>
                </div>
                }@else {
                <span [ngClass]="{'text-[6.7px]': isSmall, '!text-[#9393FF]': isFastest}"
                    class="text-neutral-700 text-14 font-semibold">
                    {{minTime ? ( minTime + ' - ' ) : ''}}
                    {{time}}

                    {{time === 1 ? 'Day' : 'Days'}}
                </span>
                }
            </div>
            <div class="flex flex-row items-center gap-[5px]">
                <div [ngClass]="{'w-4': isSmall}"
                    class="w-8 h-8 rounded-lg bg-neutral-35 mr-1 flex justify-center items-center ">
                    @if(iconCalendar){
                        <img src="/assets/icons/currency-gray.svg" alt="" srcset="">
                    }@else {
                        <img src="/assets/icons/currency.svg" alt="" srcset="">
                    }
                </div>
                <span [ngClass]="{'text-[6.7px]': isSmall, '!text-[#24B23B]': isCheapest}"
                    class="text-neutral-700 text-14 font-semibold whitespace-nowrap">
                    {{minPrice ? ( ( minPrice / 100 | currency : currency.value: 'symbol' : '1.0-0' | space) + ' - ' ) : ''}}
                    {{price / 100 | currency : currency.value : 'symbol' : '1.0-0' | space}}
                    <!-- USD GBP EUR NZD GBP -->
                </span>
            </div>
        </div>
        <!-- @if(hasSelecetBtn){
            <button (click)="onSelectProdcut(); $event.stopPropagation()" class="bg-neutral-0 border border-neutral-600 rounded-lg text-neutral-600 text-13 py-4">Select</button>
        } -->
    </div>
</div>

<mat-menu #menu="matMenu" class="w-[138px] p-[6px]">
    <div class=" flex flex-col gap-2 justify-center">
        <button *ngFor="let item of menuItems" (click)="onClick(item.id)"
            class="!p-0 rounded-md h-7 hover:bg-neutral-35">
            <div class="flex flex-row items-center ">
                <img [src]="item.icon" alt="" class="p-2">
                <span class="text-11 text-neutral-700 font-medium leading-4">{{item.name}}</span>
            </div>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="subShareMenu" class="!p-0 rounded-md h-7 hover:bg-neutral-35">
            <div class="flex flex-row items-center ">
                <img src="/assets/icons/share.svg" alt="" class="p-2">
                <span class="text-11 text-neutral-700 font-medium leading-4">Share</span>
            </div>
        </button>
    </div>
</mat-menu>

<mat-menu #subShareMenu="matMenu" class="w-[138px] p-[6px]">
    <div class=" flex flex-col gap-2 justify-center">
        <button *ngFor="let item of subShareItems" (click)="onClick(item.id)"
            class="!p-0 rounded-md h-7 hover:bg-neutral-35">
            <div class="flex flex-row items-center ">
                <img [src]="item.icon" alt="" class="p-2">
                <span class="text-11 text-neutral-700 font-medium leading-4">{{item.name}}</span>
            </div>
        </button>
    </div>
</mat-menu>