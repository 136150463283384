import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Categories, Marketplace, Products } from '../models';
import { BehaviorSubject, Observable, shareReplay, zip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalService {
  private readonly _data: Observable<Marketplace>;
  constructor(private http: HttpClient) {
    this._data = this.http.get<Marketplace>(`${environment.API_URL}external/marketplace`).pipe(shareReplay())
  }
  private currency = ''
  public currencyOptions = [
    {viewValue:'US Dollar', value:'USD', code: '$'},
    {viewValue:'Euro', value:'EUR',code: '€'},
    {viewValue:'New Zealand Dollar', value:'NZD', code: 'NZ$'},
    {viewValue:'British Pound', value:'GBP', code: '£'}
  ]

  public getCurrency(){return this.currency}
  public setCurrency(currency: string){
    
    this.currency = currency;
  }
  public getFullCurrency(){
      const found = this.currencyOptions.find(curr=> curr.value == this.currency)
      if(!found){
        return this.currencyOptions[0]
      }
      return found
  }

  marketplaceData() {
    return this._data;
  }
  getMarketplace(){
    return this.http.get<Marketplace>(`${environment.API_URL}external/marketplace`)
  }
  products(filter: HttpParams) {
    return this.http.get<Array<Categories>>(`${environment.API_URL}external/products`, {params:filter});
  }
  public product(id: string) {
    return this.http.get<Products>(
      `${environment.API_URL}external/products/${id}`
    );
  }

  public getJurneyStages() {
    return this.http.get<Array<{name:string, id: string}>>(
      `${environment.API_URL}external/journey-stage`
    );
  }
  
  public getMinMax(id: string) {
    return this.http.get<Products>(
      `${environment.API_URL}external/products/${id}/offer`
    );
  }

  registerProviderEmail(id: string){
    return this.http.get<any>(
      `${environment.API_URL}external/invitation/${id}`
    );
  }

  getCount(fetchAgency: boolean){
    return zip([
      this.http.get(`${environment.API_URL}external/count-products`),
      fetchAgency ? this.http.get(`${environment.API_URL}external/count-agencies`) : this.http.get(`${environment.API_URL}external/count-providers`),
      this.http.get(`${environment.API_URL}external/count-offers`),
    ])
  }

  getFeatured(){
    return this.http.get<Array<Products>>(`${environment.API_URL}external/featured`)
  }
}
