<div class="bg-white rounded-xl p-8 relative w-full">
    <div class="h-8">
        <div (click)="closeModal()"
            class="absolute right-4 top-3 w-8 h-8 border border-neutral-100 rounded-lg bg-neutral-0 mr-1 flex items-center justify-center cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M13.3332 13.3334L9.99985 10.0001M9.99985 10.0001L6.6665 6.66675M9.99985 10.0001L13.3332 6.66675M9.99985 10.0001L6.6665 13.3334"
                    stroke="#0F0F10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>
    <div [ngSwitch]="data.type">
        <div *ngSwitchCase="'create-journey'">
            <ng-container *ngTemplateOutlet="createJourney"></ng-container>
        </div>
        <div *ngSwitchCase="'create-category'">
            <ng-container *ngTemplateOutlet="createCategory"></ng-container>
        </div>
        <div *ngSwitchCase="'edit-category'">
            <ng-container *ngTemplateOutlet="createCategory"></ng-container>
        </div>
        <div *ngSwitchCase="'create-timeline'">
            <ng-container *ngTemplateOutlet="createTimeline"></ng-container>
        </div>
        <div *ngSwitchCase="'edit-timeline'">
            <ng-container *ngTemplateOutlet="createTimeline"></ng-container>
        </div>
        <div *ngSwitchCase="'confirm'">
            <ng-container *ngTemplateOutlet="confirm"></ng-container>
        </div>
        <div *ngSwitchCase="'ContactSales'">
            <ng-container *ngTemplateOutlet="ContactSales"></ng-container>
        </div>
        <div *ngSwitchCase="'embed-code'">
            <ng-container *ngTemplateOutlet="embedCode"></ng-container>
        </div>
        <div *ngSwitchCase="'ineligible'">
            <ng-container *ngTemplateOutlet="ineligible"></ng-container>
        </div>
        <div *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="tmplSecondWithContext; context: {bar: 42}"></ng-container>
        </div>
    </div>
</div>
<ng-template #ineligible>
    <div class="flex flex-col items-center justify-center gap-6">
        <div class="p-2 rounded-xl border border-neutral-200 bg-neutral-25">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                <path
                    d="M19 13.3795V19.7128M19 33.25C11.1299 33.25 4.75 26.8701 4.75 19C4.75 11.1299 11.1299 4.75 19 4.75C26.8701 4.75 33.25 11.1299 33.25 19C33.25 26.8701 26.8701 33.25 19 33.25ZM19.0789 24.4628V24.6211L18.9211 24.6205V24.4628H19.0789Z"
                    stroke="#FF5050" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <h1 class="text-20 text-neutral-700 font-semibold">Heads up!</h1>
        <p class="text-12 text-neutral-600 text-center leading-5">This inventory unit doesn’t satisfy the marketplace admin’s eligibility criteria for this product.</p>
        <div *ngIf="data.extras.errPrice" class="flex flex-row items-center w-full gap-2">
            <div class="p-2 rounded-xl border border-neutral-200 bg-neutral-25">
                <img src="/assets/icons/red-wallet.png" alt="">
            </div>
            <p class="text-12 text-neutral-600 text-center">Your price needs to be $15 - ${{data.extras.maxPrice}} .</p>
        </div>
        <div *ngIf="data.extras.errDays" class="flex flex-row items-center w-full gap-2">
            <div class="p-2 rounded-xl border border-neutral-200 bg-neutral-25">
                <img src="/assets/icons/red-calendar.svg" alt="">
            </div>
            <p class="text-12 text-neutral-600 text-center">Your turnaround time needs be {{data.extras.minDays}} - {{data.extras.maxDays}} days.</p>
        </div>
        <p class="text-12 text-neutral-600 text-center leading-5">You can still save this inventory unit to communicate to the site admin your willingness to offer this product at different price point or turnaround time. <br/> Just know that this inventory won’t be available to buyers for purchase until the site admin changes the settings for this product.</p>
        <button (click)="dialogRef.close(true)"
            class="bg-neutral-700 w-full font-semibold text-white px-5 py-4 rounded-lg text-13">Yes</button>
    </div>
</ng-template>
<ng-template #embedCode>
        <div class="flex flex-col px-8 pb-4">
            <h1 class="text-neutral-700 font-semibold text-20 mb-14">&lt;&gt; Embed Code</h1>
            <div class="border border-neutral-300 rounded-md p-4">
                <p class="text-13  text-neutral-600 leading-6">{{data.extras}}</p>
            </div>
            <h2 class="text-neutral-700 text-13 font-medium my-3">Copy and paste the embed code into your website's HTML</h2>
            <button (click)="copyToClipboard()" class="bg-neutral-700 w-full text-white px-5 py-4 rounded-lg text-13 mt-8">
            {{isCopied ? 'Copied!' : 'Copy to clipboard'}}
            </button>
        </div>
</ng-template>
<ng-template #ContactSales>
    <div class="flex flex-col items-center justify-center gap-6 text-center relative -top-3">
        <p class="text-20 font-medium ">Contact Sales</p>
        <p class="text-16  text-neutral-600 text-center leading-6 whitespace-break-spaces" [innerHTML]="data.extras.bodyText"></p>
    </div>
</ng-template>
<ng-template #confirm>
    <div class="flex flex-col items-center justify-center gap-6">
        <div class="p-2 rounded-xl border border-neutral-200 bg-neutral-25">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                <path
                    d="M19 13.3795V19.7128M19 33.25C11.1299 33.25 4.75 26.8701 4.75 19C4.75 11.1299 11.1299 4.75 19 4.75C26.8701 4.75 33.25 11.1299 33.25 19C33.25 26.8701 26.8701 33.25 19 33.25ZM19.0789 24.4628V24.6211L18.9211 24.6205V24.4628H19.0789Z"
                    stroke="#FF5050" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        @if(data.extras.title){
            <h1 class="text-20 text-center text-black font-medium">{{data.extras.title}}</h1>
        }
        <p class="text-12 text-neutral-600 text-center leading-5" [innerHTML]="data.extras.bodyText"></p>
        @if(data.extras.btnType){
        <div class="flex gap-7 w-full">
            <button (click)="dialogRef.close(true)"
                class="bg-primary-2 w-full font-semibold text-white px-5 py-4 rounded-lg text-13">Yes</button>
            <button (click)="dialogRef.close(false)"
                class="bg-neutral-700 w-full font-semibold text-white px-5 py-4 rounded-lg text-13">No</button>
        </div>
        }@else{
        <button (click)="dialogRef.close(true)"
            class="bg-neutral-700 w-full font-semibold text-white px-5 py-4 rounded-lg text-13">{{data.extras.btnText}}</button>
        }

    </div>
</ng-template>

<ng-template #createJourney>
    <div class="flex flex-col gap-12">
        <div class="flex flex-row h-10">
            <app-custom-input [control]="title" class="w-full px-1"
                placeholder="Untitled journey stage"></app-custom-input>
        </div>
        <button (click)="onJoruneyCreate()" class="bg-neutral-700 w-full text-white px-5 py-4 rounded-lg text-13">Save
            changes</button>
    </div>
</ng-template>
<ng-template #createCategory>
    <div class="flex flex-col gap-12">
        <div class="flex flex-row h-10">
            <div class="w-10 rounded-lg bg-neutral-35 mr-1 flex justify-center items-center border border-neutral-200"
                [style.background]="selectedColor">
                @if(selectedIcon){
                <img [src]="'/assets/icons/product/'+selectedIcon+'.svg'" [ngClass]="{'filter brightness-0 invert': vectorColor.value == 'light'}" alt="" srcset="">
                }
            </div>
            <app-custom-input [control]="title" class="w-full px-1"
                placeholder="Untitled Category |"></app-custom-input>

        </div>
        <div class=" flex flex-col gap-6">
            <div>
                <span class="text-neutral-650 text-[13px] font-semibold">Background Color</span>
                <div class="flex flex-row relative">
                    <input class="w-[42px] h-[42px] rounded-lg border-[1.25px] border-neutral-200"
                        [(colorPicker)]="selectedColor" [style.background]="selectedColor" />
                </div>
            </div>
            <div>
                <span class="text-neutral-650 text-[13px] font-semibold">Icon Color</span>
                <div class="flex flex-row relative">
                    <mat-radio-group [formControl]="vectorColor" aria-label="Select an option">
                        <mat-radio-button value="dark" class="mr-6"><span
                                class="text-13 text-neutral-700">Black</span> </mat-radio-button>
                        <mat-radio-button value="light"><span
                                class="text-13 text-neutral-700">White</span></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div>
                <span class="text-neutral-650 text-[13px] font-semibold">Icon</span>
                <div class="flex flex-wrap gap-2">
                    <div (click)="changeIcon(icon)" *ngFor="let icon of icons"
                        [ngClass]="{ 'border-black': selectedIcon == icon }"
                        [style.background]="selectedColor"
                     
                        class="w-10 h-10 border border-neutral-200 rounded-lg bg-neutral-35 flex items-center justify-center cursor-pointer">
                        <img [src]="'/assets/icons/product/'+icon+'.svg'" [ngClass]="{'filter brightness-0 invert': vectorColor.value == 'light'}" alt="" srcset="">
                    </div>
                </div>
            </div>

        </div>
        <button (click)="onCreateCategory()" class="bg-neutral-700 w-full text-white px-5 py-4 rounded-lg text-13">Save
            changes</button>
    </div>
</ng-template>
<ng-template #createTimeline>
    <div class="flex flex-col p-8">
        <h1 class="text-neutral-700 font-semibold text-20 mb-14">
            {{data.type == 'edit-timeline' ? 'Edit Step' : 'Create Step'}}
        </h1>
        <span class="text-12 text-black font-medium">Who is responsible  for completing this task</span>
        <div class="flex flex-row">
            <mat-radio-group [formControl]="stepForm.controls['assignedTo']" aria-label="Select an option">
                <mat-radio-button *ngIf="!this.data.extras.isLastStep" value="buyer" class="mr-6"><span
                        class="text-16 text-neutral-700 font-medium">Buyer</span> </mat-radio-button>
                <mat-radio-button value="provider"><span
                        class="text-16 text-neutral-700 font-medium">Provider</span></mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="!this.data.extras.isLastStep" class="mt-6">
            <h2 class="text-neutral-700 text-16 font-medium mb-2">Title</h2>
            <app-custom-input [control]="stepForm.controls['title']" class="w-full"
                placeholder="Title goes here"></app-custom-input>
        </div>

        <div class="mt-6">
            <h2 class="text-neutral-700 text-16 font-medium mb-2">A short description about this step</h2>
            <app-custom-input [control]="stepForm.controls['description']" class="w-full"
                placeholder="Enter A short description about this step"></app-custom-input>
        </div>
        <div class="flex flex-row gap-6">
            <div *ngIf="!this.data.extras.isLastStep" class="mt-6">
                <h2 class="text-neutral-700 text-16 font-medium mb-2">Duration Percentage</h2>
                <div class="flex flex-row gap-2 items-center">
                    <app-custom-input  type="number"
                    [control]="stepForm.controls['durationPercent']" class="w-14 block"
                    placeholder="0" />
                    <p class="text-23 text-neutral-700 font-semibold">%</p>
                </div>
            </div>    
            <div *ngIf="data.extras.isPaymentMilestone && stepForm.controls['assignedTo'].value == 'provider'" class="mt-6">
                <h2 class="text-neutral-700 text-16 font-medium mb-2">Payment Percentage</h2>
                <div class="flex flex-row gap-2 items-center">
                    <app-custom-input  type="number"
                    [control]="stepForm.controls['paymentPercent']" class="w-14 block"
                    placeholder="0" />
                    <p class="text-23 text-neutral-700 font-semibold">%</p>
                </div>
            </div>
        </div> 
  
        <button (click)="onStepCreate()" class="bg-neutral-700 w-full text-white px-5 py-4 rounded-lg text-13 mt-14">
            {{data.type == 'edit-timeline' ? 'Edit Step' : 'Add Step'}}
        </button>
        <button *ngIf="data.type == 'edit-timeline' && !this.data.extras.isLastStep" (click)="onStepCreate('delete')" class="bg-white w-full text-signals-red border-signals-red rounded-lg border px-5 py-4 text-13 mt-6">
            Delete step
        </button>
    </div>
</ng-template>
<ng-template #tmplSecondWithContext let-foo="bar">Output: {{foo}}</ng-template>