import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { UserApiService } from '../../../user/services/user-api.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CopyService } from '../../services/copy.service';
import { ModalComponent } from '../modal/modal.component';
import { ApiService } from '../../../admin/services/api.service';
import { ExternalService } from '../../api/external.service';
import { SpacePipe } from '../../pipes/space.pipe';
import { FeaturedDataServiceService } from '../../services/featured-data-service.service';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [CommonModule, MatMenuModule, SpacePipe],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent {
  constructor(
    private userService: UserApiService,
    private atuhService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private copyService: CopyService,
    private adminService: ApiService,
    private externalService: ExternalService,
    private featuredDataService: FeaturedDataServiceService
  ) {}
  @Input() id = '';
  @Input() journey: { name: string; id: string; } | undefined;
  @Input() tagline = '';
  @Input() title = '';
  @Input() time = 3;
  @Input() minTime: null | number = null;
  @Input() price = 500;
  @Input() minPrice: null | number = null;
  @Input() type = '';
  @Input() isSmall = false;
  @Output() onMenuClick = new EventEmitter();
  @Input() isBookmarked?: boolean;
  @Input() hasSelecetBtn = false;
  @Input() offerId?: string;
  @Input() isFastest: boolean = false;
  @Input() isCheapest: boolean = false;
  @Input() startDate?: string | null;
  @Input() endDate?: string | null;
  @Input() isFeatured = false;
  @Input() iconCalendar = false;
  currency = this.externalService.getFullCurrency();
  menuItems = [
    { id: 'edit', name: 'Edit Details', icon: '/assets/icons/Settings.svg' },
    { id: 'duplicate', name: 'Duplicate', icon: '/assets/icons/Duplicate.svg' },
  ];
  subShareItems = [
    { id: 'copy', name: 'Copy Link', icon: '/assets/icons/copyLink.svg' },
    { id: 'embed', name: 'Embed', icon: '/assets/icons/embed.svg' },
  ];
  onClick(event: string) {
    if (event == 'copy') {
      const link = window.location.origin + `?productId=${this.id}`;
      this.copyService.copyToClipboard(link);
    } else if (event == 'embed') {
      const link = window.location.origin + `?productId=${this.id}`;
      const iframe = `<iframe src="${link}"></iframe>`;
      this.copyService.copyToClipboard(iframe);
    }

    this.onMenuClick.emit(event);
  }
  isSuperAdmin = false;
  ngOnInit() {
    this.isSuperAdmin = this.atuhService.isSuperAdmin();
    if (!this.isBookmarked) {
      this.userService.bookmarkedProducts.subscribe((res) => {
        this.isBookmarked = !!res.find((prod) => prod == this.id);
      });
    }
    if(this.type == 'admin'){
      this.featuredDataService.getFeaturedProducts().subscribe(res=>{
        // console.log('Ovde!',res);
        if(res.find(prod => prod.id == this.id)){
          this.isFeatured = true
        }else{
          this.isFeatured = false
        }
      })
    }
  }
  onBookmark() {
    if (!this.atuhService.isBuyer()) {
      this.router.navigate(['/login']);
      return;
    }
    if (this.isBookmarked) {
      this.userService.removeBookmark(this.id).subscribe((res) => {
        this.isBookmarked = false;
      });
      return;
    }
    this.userService.setBookmark(this.id).subscribe((res) => {
      this.isBookmarked = true;
    });
  }

  goToDetails() {
    if (this.type == 'admin') {
      this.onMenuClick.emit('edit');
      return;
    }
    this.router.navigate([`/product/${this.id}`]);
  }
  onSelectProdcut() {
    if (!this.offerId) {
      return;
    }
    this.userService.productSelected(this.offerId).subscribe((res) => {
      this.router.navigate([`/order/${res.id}`]);
    });
  }

  onProductDelete() {
    const modalRef = this.dialog.open(ModalComponent, {
      width: '440px',
      data: {
        type: 'confirm',
        extras: {
          btnType: 'yesNo',
          bodyText: 'Are you sure you wanna delete this product?',
        },
      },
    });
    modalRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.adminService.deleteProduct(this.id).subscribe((res) => {
          this.adminService.productRefech.next(true);
        });
      }
    });
  }
  onFeatured(){
    if(!this.isFeatured && this.featuredDataService.getLength() == 3){
      const modalRef = this.dialog.open(ModalComponent, {
        width: '440px',
        data: {
          type: 'confirm',
          extras: {
            btnText: 'Okay',
            bodyText: 'You can not feature more then 3 products',
          },
        },
      });
      return;
    }
    this.adminService.featuredProduct(this.id).subscribe(res=>{
      console.log(res);
      // this.isFeatured = !this.isFeatured;
      this.featuredDataService.setFeaturedProducts(res)
    })
  }
}
