import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { CustomInputComponent } from '../custom-input/custom-input.component';
import {MatRadioModule} from '@angular/material/radio';
import { CopyService } from '../../services/copy.service';
export interface ModalData {
  type: string;
  extras: any
}
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    CustomInputComponent,
    CustomSelectComponent,
    ReactiveFormsModule,
    ColorPickerModule,
    MatRadioModule
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  providers: [ColorPickerService],
})
export class ModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private copyService: CopyService
  ) {}
  ngOnInit(){
    if(this.data.type == 'edit-timeline'){
      this.stepForm.patchValue(this.data.extras)
    }
    if(this.data.type == 'edit-category'){
      const catData = this.data.extras
      this.title.setValue(catData.name)
      this.selectedIcon = catData.icon
      this.selectedColor = catData.iconColor
      this.vectorColor.setValue(catData.vectorColor || 'dark')
    }
    if(this.data.type == 'create-journey'){
      this.title.patchValue(this.data.extras.title)
    }
  }
// CREATE CATEGORY
  title = new FormControl('')
  vectorColor = new FormControl('dark')
  selectedIcon = '';
  public selectedColor: string = '#F5F6F7';
  icons = Array.from(Array(16).keys()).map(i=>String(i+1))
  changeIcon(icon: any) {
    this.selectedIcon = icon;
  }
  onCreateCategory() {
    const data:any = {
      name:this.title.value,
      icon: this.selectedIcon,
      iconColor: this.selectedColor,
      vectorColor: this.vectorColor.value
    }
    if(this.data.type == 'edit-category'){
      data.id = this.data.extras.id
    }
    this.dialogRef.close(data);
  }

  onJoruneyCreate(){
    this.dialogRef.close(this.title.value);
  }

  closeModal() {
    this.dialogRef.close();
  }

  // STEP - PROJECT TIMELINE
  stepForm = new FormGroup({
    id: new FormControl(''),
    title: new FormControl('', [Validators.required, Validators.maxLength(54)]),
    description: new FormControl(''),
    assignedTo: new FormControl('buyer'),
    paymentPercent: new FormControl(0, Validators.required),
    durationPercent: new FormControl(0, Validators.required)
  })
  onStepCreate(action?:string) {
    if(this.stepForm.invalid){
      return
    }
    const data = this.stepForm.value
    if(data.assignedTo == 'buyer'){
      data.paymentPercent = 0
    }
    this.dialogRef.close({...data, editable:true, action});
  }
  // ENDOF STEP - PROJECT TIMELINE
  isCopied = false;
  copyToClipboard(){
    this.isCopied = true;
    setTimeout(()=>this.isCopied = false,3500)
    this.copyService.copyToClipboard(this.data.extras)
  }
}
