@if (labelCustom) {
    <div class="pb-2">
      <span class="text-12 text-neutral-700">{{labelCustom}}</span>
    </div>
}
@if (label) {
    <div class="text-left">
      <span class="text-[10px] text-neutral-800 font-normal leading-[13.5px]">{{label}}</span>
    </div>
}
<div class="relative">

    <input [formControl]="control" [ngClass]="[size == 'xs' ? 'p-2 text-11':
    size == 's' ? 'p-3 text-11' : 'px-4 py-[14px] text-[12px]',
    icon ? 'pl-9' : '',
    control.invalid && submitted ? 'invalid' : '' ]"
        class="w-full bg-neutral-0 border-[1.25px] border-neutral-100 rounded-md font-medium outline-none
        disabled:bg-neutral-100 disabled:text-neutral-300 disabled:border-neutral-300
        focus:border-neutral-300 focus:bg-neutral-25
        mb-1
        " [type]="type" name=""
        [placeholder]="placeholder">
    @if(icon){
    <img [src]="icon" class="absolute pl-3 top-1/2 -translate-y-1/2" alt="" srcset="">
    }
    <small class="text-signals-error absolute -bottom-3 left-0 text-[10px] font-medium whitespace-nowrap" *ngIf="control.invalid && submitted">
        <span *ngIf="control.errors?.['required']">Required field*</span>
        <span *ngIf="control.errors?.['email']">Invalid email</span>
        <span *ngIf="control.errors?.['min']">Must be grater then {{control.errors?.['min'].min}}</span>
        <span *ngIf="control.errors?.['max']">Must be lower then {{control.errors?.['max'].max}}</span>
        <span *ngIf="control.errors?.['notMach']">Confirm Password don't match with new password</span>
        <span *ngIf="control.errors?.['minValHigerThanMax']">Cannot be higer than Max value</span>
        <span *ngIf="control.errors?.['maxValLowerThanMin']">Cannot be lower than Min value</span>
        <span *ngIf="control.errors?.['priceLowerThen']">Cannot be lower than $15</span>
        <span *ngIf="control.errors?.['wrongPass']">The password you entered is incorrect.</span>
        <span *ngIf="control.errors?.['pattern']">No decimal numbers</span>
        <span *ngIf="control.errors?.['providerCount']">The group has only {{control.errors?.['providerCount'].count}} provider/s!</span>
        <span *ngIf="control.errors?.['emailInUse']">This email is already registered, please click login.</span>
        <span *ngIf="control.errors?.['invalidDate']">{{
        control.errors?.['invalidDate'].over ? 
        "The schedule date can not be bigger the 180 days"
        : control.errors?.['invalidDate'].under ? 
        "The schedule date can not be in the past"
        :"The date you entered is invalid."}}</span>
        <span *ngIf="control.errors?.['maxlength']">Cannot be longer then {{control.errors?.['maxlength'].requiredLength}} characters</span>
    </small>
</div>