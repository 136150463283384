import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss',
})
export class CustomInputComponent {
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl('');
  @Input() size: string = '';
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() labelCustom: string = '';

  constructor(public parentFormGroup: FormGroupDirective) {}
  get submitted() {
    const isSubmitted = this.parentFormGroup.submitted;
    return !!(
      this.control &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched || isSubmitted)
    );
  }
}
